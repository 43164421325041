import { inject } from 'vue';
import { Emitter, Handler } from 'mitt';

type Events = {
  openAssessmentGuide: undefined;
  setTrainingListHeight: undefined;
  updateSubjective: undefined;
  sendMessage: string;
  userInputOnClick: undefined;
  userBirthOnClick: undefined;
  userGenderOnClick: undefined;
  userEducationOnClick: undefined;
  playAudio: string;
  setTrainingTimer: undefined;
  countUpTrainingQuestionCount: undefined;
  openExitDialog: undefined;
  openRetryDialog: string;
  sendPass: string;
  setQuestionEndTime: undefined;
  getTrainingResult: boolean;
  openCenterDialog: string;
  handleCrosswordPuzzle: string;
  handleSequenceOrder: string;
  handlePassAble: boolean;
  handleInputPassAble: boolean;
  micAccessInteraction: undefined;
};
type Key = keyof Events;
export type TEvents = undefined | string | boolean;

export default function useMitt() {
  const mitt = inject('mitt') as Emitter<Events>;

  const on = (key: keyof Events, handler: Handler<Events[Key]>) =>
    mitt.on(key, handler);
  const off = (key: keyof Events, handler: Handler<Events[Key]>) =>
    mitt.off(key, handler);

  const clear = () => mitt.all.clear();

  const emit = (key: keyof Events, event: Events[Key]) => mitt.emit(key, event);

  return { on, off, emit, clear };
}
