import { ComponentInternalInstance, getCurrentInstance } from 'vue';

export default function useUtil() {
  const getComponentName = (): string | undefined => {
    const internalInstance: ComponentInternalInstance | null =
      getCurrentInstance();
    if (!internalInstance) return;
    return internalInstance.type.__name;
  };
  const delay = (num?: number) =>
    new Promise<void>((resolve) => setTimeout(() => resolve(), num));

  return { delay, getComponentName };
}
