import { boot } from 'quasar/wrappers';
import useHaiiExAuth from 'src/composables/server/useHaiiExAuth';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
// import { useSettingStore } from 'stores/useSettingStore';

export default boot(async ({ router }) => {
  const exAuth = useHaiiExAuth();
  // const settingStore = useSettingStore();
  const isProduction = process.env.VUE_APP_ENV === 'prod';
  const signInRedirectURI = window.location.origin + '/at/home';
  // const lang = settingStore.getSetting.locale;
  exAuth.init({
    serviceName: 'alztalk',
    isProduction,
    signInRedirectURI,
    // lang,
  });

  router.beforeEach(
    async (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      const userAgent = navigator.userAgent;
      const isApp = userAgent.includes('haii.hybrid;');
      if (!to.meta?.noAuth && !isApp) await exAuth.manageToken(false);
      next();
    }
  );
});
