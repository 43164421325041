import { computed, Ref, ref } from 'vue';
import axios from 'axios';
import { decode, encode } from 'js-base64';
import { tokenResponseType, tokenType } from 'src/types/constType';
import { InitType } from 'src/composables/server/useHaiiExAuth';
import { useGeneralStore } from 'stores/useGeneralStore';
import { refreshToken } from 'src/composables/useAppInterface';

const $axios = axios.create({ withCredentials: true });
const initParam: Ref<InitType> = ref({} as InitType);

// base64 Encode
function queryEncode(authorize: any): string {
  return encode(JSON.stringify(authorize));
}

// base64 decode
function queryDecode(value: string): string {
  return decode(value);
}

export default function useAlzguardExAuth() {
  const generalStore = useGeneralStore();

  // serverUrl
  const serverUrl = computed((): string => {
    if (initParam.value.isProduction) return 'haii.io';
    return 'haiidev.co.kr';
  });

  function init(newInitParam: InitType): void {
    initParam.value = newInitParam;
  }

  // token 을 decoding 하여 Expire 유무 체크
  function isRemainExpire(token: string): boolean {
    const payload = JSON.parse(queryDecode(token.split('.')[1])) as {
      exp: number;
    };

    const now = new Date().getTime();
    return now <= payload.exp * 1000;
  }

  // refresh token을 사용하여 access
  async function resetAlzguardAccessToken(
    tokenData: tokenType
  ): Promise<boolean> {
    try {
      const refreshServerUrl = `https://auth.${serverUrl.value}/refresh`;
      const headers = {
        Authorization: 'Bearer ' + tokenData.AccessToken,
      };
      const data = { rt: tokenData.RefreshToken };

      const res = await $axios({
        method: 'post',
        url: refreshServerUrl,
        headers: headers,
        data: data,
      });

      if (res.data.code === 200) {
        generalStore.setToken(res.data.data);
      }
      return res.data.code === 200;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async function manageAlzguardToken(tokenData: tokenType): Promise<string> {
    if (!tokenData) return tokenResponseType.expired;

    //  프론트에서 JWT 토큰을 디코딩하여 access token Expire date 확인
    // 토큰이 있고 exp 가 남았을 경우
    if (isRemainExpire(tokenData.AccessToken)) {
      generalStore.setToken(tokenData);
      return tokenResponseType.success;
    }

    // 토큰 있고 exp 가 안남았을 때 -> 토큰  재 발행
    const refreshTokenState = await resetAlzguardAccessToken(tokenData);

    // // 정상 재 발행
    if (refreshTokenState) {
      refreshToken(generalStore.getToken);
      return tokenResponseType.refresh;
    }

    // //  refresh token 마저 만료 됐으면 로그아웃
    generalStore.setToken({} as tokenType);
    return tokenResponseType.expired;
  }

  return {
    init,
    isRemainExpire,
    resetAlzguardAccessToken,
    manageAlzguardToken,
  };
}
