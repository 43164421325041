/*
1. useHaiiExAuth 사용
2. 로그인 상태 관리는 서버에서 (쿠키 유효 상태로)
3. 첫 실행시 init 필요
4. mangeToken 를 통해 자동적으로 상태 관리하거나 개별적으로 컨트롤 가능
 */

import { computed, Ref, ref } from 'vue';
import axios, { AxiosError } from 'axios';
import cookie from 'js-cookie';
import { decode, encode } from 'js-base64';

export type ServiceNameType = 'alztalk' | 'forme' | 'tess' | 'stc';

export type InitType = {
  serviceName: ServiceNameType; // 서비스명
  isProduction: boolean;
  signInRedirectURI?: string;
  lang?: string;
};

export type ChangePWType = {
  oldPw: string;
  newPW: string;
};

export type LoginInfoType = {
  email: string;
  pw: string;
};

export type AuthorizeType = Pick<
  InitType,
  'serviceName' | 'signInRedirectURI' | 'lang'
>;

export type UserInfoType = {
  banned_flag: boolean;
  birthday: string;
  closed_flag: boolean;
  displayName: string;
  email: string;
  emailVerified: boolean;
  gender: number;
  phone: string;
  phoneVerified: boolean;
  realName: string;
  realNameVerified: boolean;
  suspended_flag: boolean;
  uuid: string;
};

const $axios = axios.create({ withCredentials: true });
const initParam: Ref<InitType> = ref({} as InitType);
export default function useHaiiExAuth() {
  // serverUrl
  const serverUrl = computed((): string => {
    if (initParam.value.isProduction) return 'haii.io';
    return 'haiidev.co.kr';
  });

  function init(newInitParam: InitType): void {
    initParam.value = newInitParam;
  }

  // 로그인 메서드
  // redirectURI 가 설정되지않았을 경우 원래 페이지로 리턴
  // base64 로 encode 후 redirect
  function signIn(isAdmin: boolean): void {
    const authorize: AuthorizeType = {
      serviceName: initParam.value.serviceName,
      signInRedirectURI:
        initParam.value.signInRedirectURI ?? window.location.href,
      lang: initParam.value.lang ?? 'ko',
    };

    const queryBase64 = queryEncode(authorize);

    window.location.href = isAdmin
      ? `https://${serverUrl.value}/admin/auth/signin?ex=${queryBase64}`
      : `https://${serverUrl.value}/auth/signin/web?ex=${queryBase64}`;
  }

  // 로그아웃 메서드
  function signOut(isAdmin: boolean): void {
    cookie.remove(isAdmin ? '_aat' : '_at', {
      domain:
        process.env.VUE_APP_ENV === 'prod' ? '.haii.io' : '.haiidev.co.kr',
    });
    location.reload();
  }

  // token 을 decoding 하여 Expire 유무 체크
  function isRemainExpire(token: string): boolean {
    const payload = JSON.parse(queryDecode(token.split('.')[1])) as {
      exp: number;
    };

    const now = new Date().getTime();
    return now <= payload.exp * 1000;
  }

  // refresh token을 사용하여 access
  async function resetAccessToken(isAdmin: boolean): Promise<boolean> {
    try {
      const refreshServerUrl = isAdmin
        ? `https://auth.${serverUrl.value}/admin/refresh`
        : `https://auth.${serverUrl.value}/refresh`;

      const res = await $axios.get(refreshServerUrl);

      return res.data.code === 200;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  // 비밀번호 변경 메서드
  async function changePW(
    isAdmin: boolean,
    param: ChangePWType
  ): Promise<boolean> {
    try {
      const cookieName = isAdmin ? '_aat' : '_at';
      if (!cookie.get(cookieName)) return false;

      const changePwServerUrl = isAdmin
        ? `https://auth.${serverUrl.value}/admin/changepw`
        : `https://auth.${serverUrl.value}/changepw`;
      await $axios.post(changePwServerUrl, {
        old: param.oldPw,
        new: param.newPW,
      });
      return true;
    } catch (e) {
      const err = e as AxiosError<any>;
      const code = err.response?.data.code;
      console.log(code);
      if (code === 418) {
        console.log('비밀번호 변경 실패-' + err.response?.data.message);
      } else {
        console.log('비밀번호 변경 실패--' + err.response?.data.message);
      }
      return false;
    }
  }

  // 비밀번호 검증 메서드
  async function validPW(isAdmin: boolean, pw: string): Promise<boolean> {
    try {
      const cookieName = isAdmin ? '_aat' : '_at';
      if (!cookie.get(cookieName)) return false;

      const validPwServerUrl = isAdmin
        ? `https://auth.${serverUrl.value}/admin/checkpw`
        : `https://auth.${serverUrl.value}/checkpw`;

      await $axios.post(validPwServerUrl, {
        pw,
      });
      return true;
    } catch (e) {
      const err = e as AxiosError<any>;
      const code = err.response?.data.code;
      console.log(code);
      if (code === 418) {
        console.log('잘못된 비밀번호--' + err.response?.data.message);
      } else {
        console.log('error--' + err.response?.data.message);
      }

      return false;
    }
  }

  function setLocalToken() {
    const jwt =
      'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJkbiI6IuuwleywrOyYgSIsImVtIjoiY2hhbkBoYWlpLmlvIiwiZW12Ijp0cnVlLCJleHAiOjE2OTEwNDI0ODEsInN1YiI6ImIwMzg5Njc2LWU5ODAtNDMzMC04YTI5LTUxZDUxMjk3MjA4MiIsInJvbCI6WyJzdGMuMC5PV05FUiIsInRlc3MuMC5PV05FUiIsInRlc3MuMS5JTlZJVEUiLCJmb3JtZS4wLk9XTkVSIl19.f9o4YL7IHn5cGfYDCmEWj1-CabgTiQ-XkzKBhTzqPT2usFHWgwAQ7Jf71XBqJoA0FpFVyFVFvDgEpEd472Tw1g';
    document.cookie = `_aat=${jwt}`;
  }

  async function manageToken(isAdmin: boolean): Promise<void> {
    // // local 일 땐 사용 불가, 정확히는 프로토콜이 https 여야함
    if (window.location.protocol == 'http:') {
      setLocalToken();
      return;
    }

    // 토큰 가져오기
    const token = cookie.get(isAdmin ? '_aat' : '_at');

    // 토큰이 없을 때
    if (!token) return signIn(isAdmin);

    //  프론트에서 JWT 토큰을 디코딩하여 access token Expire date 확인
    // 토큰이 있고 exp 가 남았을 경우

    if (isRemainExpire(token)) return;

    // 토큰 있고 exp 가 안남았을 때 -> 토큰  재 발행
    const refreshTokenState = await resetAccessToken(isAdmin);
    // // 정상 재 발행
    if (refreshTokenState) return;
    //
    // //  refresh token 마저 만료 됐으면 로그아웃
    return signOut(isAdmin);
  }

  // base64 Encode
  function queryEncode(authorize: any): string {
    return encode(JSON.stringify(authorize));
  }

  // base64 decode
  function queryDecode(value: string): string {
    return decode(value);
  }

  return {
    init,
    signIn,
    signOut,
    isRemainExpire,
    resetAccessToken,
    changePW,
    validPW,
    manageToken,
  };
}
